import PhysicalPersonService from "@/core/services/physical-person/physical-person.service";
import i18n from "@/core/plugins/vue-i18n";

async function createPhysicalPerson(physicalPerson) {
  try {
    const out = await PhysicalPersonService.create(physicalPerson);
    return out;
  } catch (err) {
    if (err?.response?.status === 400) {
      throw err.response.data;
    }

    throw err?.response?.data?.detail ?? i18n.t("FAILED_TO_CREATE");
  }
}

async function createPhysicalPersonIndividualCompany(physicalPersonId, vat) {
  try {
    const out = await PhysicalPersonService.individualCompany(physicalPersonId, vat);
    return out;
  } catch (err) {
    if (err?.response?.status === 400) {
      throw err.response.data;
    }

    throw err?.response?.data?.detail ?? i18n.t("FAILED_TO_CREATE");
  }
}

export { createPhysicalPerson, createPhysicalPersonIndividualCompany };
