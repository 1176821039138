import PhysicalPersonAddressService from "@/core/services/physical-person/physical-person-address.service";

async function addPhysicalPersonAddress(address) {
  return await PhysicalPersonAddressService.create(address);
}

function getPhysicalPersonHomeResidenceCountry(physicalPerson) {
  if (!physicalPerson) return null;

  const address = physicalPerson.physicalpersonaddress_set.find(address => address.kind == 0 || address.kind == 1);

  if (!address) return null;

  if (address?.address?.address_components) {
    const city = address.address.address_components.find(ac => ac.types.includes("country"));
    return city?.short_name;
  }

  if (address.address.locality) {
    return address?.address?.locality?.state?.country?.code;
  }

  return null;
}

export { addPhysicalPersonAddress, getPhysicalPersonHomeResidenceCountry };
