import DateService from "@/core/services/date.service";
import PhysicalPersonFiscalService from "@/core/services/physical-person/physical-person-fiscal.service";

async function getValidFiscalCode(physicalPerson) {
  if (!physicalPerson) return null;

  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  return physicalPerson.fiscalid_set.find(fiscal => DateService.isBefore(tomorrow, fiscal.valid_to));
}

async function generateFiscalId(fiscalIdCountry, physicalPersonData) {
  try {
    return await PhysicalPersonFiscalService.generateFiscalId({
      country: fiscalIdCountry,
      physical_person_data: physicalPersonData
    });
  } catch (err) {
    throw err?.response?.data?.error;
  }
}

export { getValidFiscalCode, generateFiscalId };
