import i18n from "@/core/plugins/vue-i18n";
import PhysicalPersonA1ModuleService from "@/core/services/physical-person/physical-person-a1-module.service";

// AKA postingcertificate

async function getPhysicalPersonA1ModulePaginated(personId, { perPage, page, sortBy, sortDesc, search }) {
  try {
    return await PhysicalPersonA1ModuleService.getAll(personId, {
      page,
      perPage,
      sortBy,
      sortDesc,
      search,
    });
  } catch (err) {
    throw err?.response?.data?.detail ?? i18n.t("PHYSICAL_PERSON.A1_MODULE.GET_FAIL");
  }
}

async function getA1CertificateKinds() {
  const options = await PhysicalPersonA1ModuleService.options();
  return options?.data?.actions?.POST?.certificate_kind?.choices;
}

async function getA1ModuleCountriesIso() {
  try {
    return await PhysicalPersonA1ModuleService.getPostingCertificateCountries();
  } catch (err) {
    return [];
  }
}

async function createA1Module(a1Module) {
  try {
    return await PhysicalPersonA1ModuleService.create(a1Module);
  } catch (err) {
    if (err?.response?.status === 400) {
      throw err.response.data;
    }

    throw err?.response?.data?.detail ?? i18n.t("FAILED_TO_CREATE");
  }
}

async function updateA1Module(a1Module) {
  try {
    return await PhysicalPersonA1ModuleService.update(a1Module, a1Module.id);
  } catch (err) {
    if (err?.response?.status === 400) {
      throw err.response.data;
    }

    throw err?.response?.data?.detail ?? i18n.t("FAILED_TO_UPDATE");
  }
}

async function deleteA1Module(a1Module) {
  try {
    return await PhysicalPersonA1ModuleService.delete(a1Module);
  } catch (err) {
    throw err?.response?.data?.detail ?? i18n.t("FAILED_TO_DELETE");
  }
}

async function getA1Module(a1ModuleId) {
  try {
    return await PhysicalPersonA1ModuleService.getOne(a1ModuleId);
  } catch (err) {
    throw err?.response?.data?.detail ?? i18n.t("PHYSICAL_PERSON.A1_MODULE.GET_FAIL_SINGLE");
  }
}

export {
  getPhysicalPersonA1ModulePaginated,
  getA1CertificateKinds,
  getA1ModuleCountriesIso,
  getA1Module,
  deleteA1Module,
  updateA1Module,
  createA1Module,
};
