import i18n from "@/core/plugins/vue-i18n";
import PhysicalPersonEuropeanDrivingLicenseService from "@/core/services/physical-person/physical-person-europeandrivinglicense.service";

async function getEuDrivingLicensesPaginated(personId, { perPage, page, sortBy, sortDesc, search }) {
  try {
    return await PhysicalPersonEuropeanDrivingLicenseService.getAll(personId, {
      page,
      perPage,
      sortBy,
      sortDesc,
      search,
    });
  } catch (err) {
    throw err?.response?.data?.detail ?? i18n.t("PHYSICAL_PERSON.DRIVING_LICENSE.GET_FAIL");
  }
}

async function createEuropeanDrivingLicense(drivingLicense) {
  try {
    return await PhysicalPersonEuropeanDrivingLicenseService.create(drivingLicense);
  } catch (err) {
    if (err?.response?.status === 400) {
      throw err.response.data;
    }

    throw err?.response?.data?.detail ?? i18n.t("FAILED_TO_CREATE");
  }
}

async function getDrivingLicenseCodeKinds() {
  const options = await PhysicalPersonEuropeanDrivingLicenseService.options();
  return options?.data?.actions?.POST?.license_code?.choices;
}

async function updateEuropeanDrivingLicense(euDrivingLicense) {
  try {
    return await PhysicalPersonEuropeanDrivingLicenseService.update(euDrivingLicense, euDrivingLicense.id);
  } catch (err) {
    if (err?.response?.status === 400) {
      throw err.response.data;
    }

    throw err?.response?.data?.detail ?? i18n.t("FAILED_TO_UPDATE");
  }
}

async function deleteEuropeanDrivingLicense(euDrivingLicense) {
  try {
    return await PhysicalPersonEuropeanDrivingLicenseService.delete(euDrivingLicense);
  } catch (err) {
    throw err?.response?.data?.detail ?? i18n.t("FAILED_TO_DELETE");
  }
}

async function getEuropeanDrivingLicense(euDrivingLicenseId) {
  try {
    return await PhysicalPersonEuropeanDrivingLicenseService.getOne(euDrivingLicenseId);
  } catch (err) {
    throw err?.response?.data?.detail ?? i18n.t("PHYSICAL_PERSON.DRIVING_LICENSE.GET_FAIL_SINGLE");
  }
}

export {
  createEuropeanDrivingLicense,
  getDrivingLicenseCodeKinds,
  updateEuropeanDrivingLicense,
  deleteEuropeanDrivingLicense,
  getEuropeanDrivingLicense,
  getEuDrivingLicensesPaginated
};
